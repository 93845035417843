import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The examples below show you how you can configure the PaymentsDS JavaScript SDK`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { Client } from "@paymentsds/mpesa"

const client = new Client({
  apiKey: "<REPLACE>", // API Key
  publicKey: "<REPLACE>", // Public Key
  serviceProviderCode: "<REPLACE>", // input_ServiceProviderCode,
  initiatorIdentifier: "<REPLACE>", // input_InitiatorIdentifier,
  securityIdentifier: "<REPLACE>", // input_SecurityCredential
  timeout: "<REPLACE>", // time in seconds
  debugging: true,
  verifySSL: false,
  userAgent: "<REPLACE>",
})
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { Client } from "@paymentsds/mpesa"

const client = new Client({
  accessToken: "<REPLACE>", // Precomputed access token
  serviceProviderCode: "<REPLACE>", // input_ServiceProviderCode,
  initiatorIdentifier: "<REPLACE>", // input_InitiatorIdentifier,
  securityIdentifier: "<REPLACE>", // input_SecurityCredential
  timeout: "<REPLACE>", // time in seconds
  debugging: true,
  verifySSL: false,
  userAgent: "<REPLACE>",
})
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      